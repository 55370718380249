import { useMemo, memo } from 'react';
import {
  Table,
  useTranslations,
  IUseTableReturn,
  IUseQueryTableFetchReturn,
  IUseTableLocalizationReturn,
  getTimeFromNow,
  Grid,
  LoginAvatar,
  TypographyWithTooltip,
  IMaterialTableOptions,
} from '@uniqkey-frontend/shared-app';
import {
  GetVaultPasswordsResponseV2Model,
} from '@uniqkey-backend-partner/api-client';
import {
  OWNERSHIP_TRANSLATION_KEYS,
  TWO_FA_TRANSLATION_KEYS,
  TWO_FA_COLORS,
} from '../../../constants';
import {
  TGetOrganizationEmployeeAccountVaultPasswordsV2Method,
} from '../../../hooks/useEmployeeAccountsAPI/interfaces';
import ColumnWithSecurityScore from '../../ColumnWithSecurityScore';

export interface IOrganizationEmployeeLoginsTableRow extends GetVaultPasswordsResponseV2Model {
  lastActivity: string | null;
}

interface IOrganizationEmployeeLoginsTableProps extends
  Omit<IUseTableReturn<IOrganizationEmployeeLoginsTableRow>, 'selectedRows' | 'resetTableMethods'>,
  Omit<
    IUseQueryTableFetchReturn<TGetOrganizationEmployeeAccountVaultPasswordsV2Method>,
    'data' | 'resetQuery' | 'isFetchedAfterMount'
  >
{
  logins: IUseQueryTableFetchReturn<TGetOrganizationEmployeeAccountVaultPasswordsV2Method>['data'];
  selectedLogins: IUseTableReturn<IOrganizationEmployeeLoginsTableRow>['selectedRows'];
  localization: IUseTableLocalizationReturn['localization'];
}

const TABLE_OPTIONS: IMaterialTableOptions<IOrganizationEmployeeLoginsTableRow> = {
  selection: false,
};

const OrganizationEmployeeLoginsTable = (props: IOrganizationEmployeeLoginsTableProps) => {
  const {
    logins: preLogins,
    isLoading,
    total,
    localization,
    activePage,
    setActivePage,
    perPage,
    onPerPageChange,
    columnOrderBy,
    onColumnOrderByChange,
    columnOrderDirection,
    onColumnOrderDirectionChange,
    selectedLogins,
    onRowSelectionChange,
    createColumns,
  } = props;

  const { t, currentLanguage } = useTranslations();

  const logins = useMemo(() => preLogins.map((login) => ({
    ...login,
    lastActivity: getTimeFromNow({
      date: login.activityAt,
      locale: currentLanguage,
    }),
  })), [preLogins, currentLanguage]);

  const columns = useMemo(() => createColumns([
    {
      title: t('organizationEmployeePage.loginsTab.service'),
      field: 'applicationName',
      render: (rowData) => (
        <Grid container flexWrap="nowrap" alignItems="center">
          <LoginAvatar src={rowData.iconUrl} />
          <TypographyWithTooltip variant="body2">
            {rowData.applicationName}
          </TypographyWithTooltip>
        </Grid>
      ),
      width: '15%',
    },
    {
      title: t('organizationEmployeePage.loginsTab.loginName'),
      field: 'name',
      render: (rowData) => (
        <TypographyWithTooltip variant="body2">
          {rowData.name}
        </TypographyWithTooltip>
      ),
      width: '15%',
    },
    {
      title: t('organizationEmployeePage.loginsTab.username'),
      field: 'username',
      render: (rowData) => (
        <TypographyWithTooltip variant="body2">
          {rowData.username}
        </TypographyWithTooltip>
      ),
      width: '15%',
    },
    {
      title: t('organizationEmployeePage.loginsTab.securityScore'),
      field: 'strength',
      render: (rowData) => <ColumnWithSecurityScore securityScore={rowData.securityScore} />,
      width: '15%',
    },
    {
      title: t('organizationEmployeePage.loginsTab.2fa'),
      field: 'password2FaStatus',
      render: (rowData) => (
        <TypographyWithTooltip variant="body2" color={TWO_FA_COLORS[rowData.password2FaStatus]}>
          {t(TWO_FA_TRANSLATION_KEYS[rowData.password2FaStatus])}
        </TypographyWithTooltip>
      ),
      width: '10%',
    },
    {
      title: t('organizationEmployeePage.loginsTab.managedBy'),
      field: 'ownership',
      render: (rowData) => (
        <TypographyWithTooltip variant="body2">
          {t(OWNERSHIP_TRANSLATION_KEYS[rowData.ownership])}
        </TypographyWithTooltip>
      ),
      width: '15%',
    },
    {
      title: t('organizationEmployeePage.loginsTab.lastActivity'),
      field: 'activityAt',
      render: (rowData) => (
        <TypographyWithTooltip variant="body2">
          {rowData.lastActivity}
        </TypographyWithTooltip>
      ),
      width: '15%',
    },
  ]), [createColumns, t]);

  return (
    <Table
      t={t}
      totalItems={total}
      columns={columns}
      data={logins}
      isLoading={isLoading}
      options={TABLE_OPTIONS}
      activePage={activePage}
      onActivePageChange={setActivePage}
      perPage={perPage}
      onPerPageChange={onPerPageChange}
      columnOrderBy={columnOrderBy}
      onColumnOrderByChange={onColumnOrderByChange}
      columnOrderDirection={columnOrderDirection}
      onColumnOrderDirectionChange={onColumnOrderDirectionChange}
      selectedRows={selectedLogins}
      onSelectionChange={onRowSelectionChange}
      localization={localization}
      rowSelectByKey="vaultId"
      rowHeight={51}
    />
  );
};

export default memo(OrganizationEmployeeLoginsTable);
