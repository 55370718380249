import { memo } from 'react';
import { isNumber } from 'lodash';
import { useTranslations, SecurityScoreIcon } from '@uniqkey-frontend/shared-app';

interface IColumnWithSecurityScoreProps {
  securityScore?: number | null;
}

const ColumnWithSecurityScore = (params: IColumnWithSecurityScoreProps) => {
  const { securityScore } = params;
  const { t } = useTranslations();
  if (isNumber(securityScore)) {
    return <SecurityScoreIcon percentage={securityScore} />;
  }
  return t('common.noScore');
};

export default memo(ColumnWithSecurityScore);
