import { useCallback, useMemo, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Table,
  TypographyWithTooltip,
  useTranslations,
  IUseTableReturn,
  IUseQueryTableFetchReturn,
  IUseTableLocalizationReturn,
  IMaterialTableOptions,
  IMaterialTableProps,
} from '@uniqkey-frontend/shared-app';
import {
  GetExtendedOrganizationsResponseModel,
} from '@uniqkey-backend-partner/api-client';
import PageRouteEnum from '../../../enums/PageRouteEnum';
import {
  TGetExtendedOrganizationsMethod,
} from '../../../hooks/usePartnerOrganizationsAPI/interfaces';

export interface IOrganizationsExtendedTableRow extends GetExtendedOrganizationsResponseModel {}

interface IOrganizationsExtendedTableProps extends
  Omit<IUseTableReturn<IOrganizationsExtendedTableRow>, 'selectedRows' | 'resetTableMethods'>,
  Omit<IUseQueryTableFetchReturn<TGetExtendedOrganizationsMethod>,
    'data' | 'resetQuery' | 'isFetchedAfterMount'
  >
{
  organizationsExtended: IUseQueryTableFetchReturn<TGetExtendedOrganizationsMethod>['data'];
  selectedOrganizationsExtended: IUseTableReturn<
    IOrganizationsExtendedTableRow
  >['selectedRows'];
  localization: IUseTableLocalizationReturn['localization'];
  options?: IMaterialTableOptions<IOrganizationsExtendedTableRow>;
}

const COLUMN_TOOLTIP_PROPS = { cursorPointer: true };

const OrganizationsExtendedTable = (props: IOrganizationsExtendedTableProps) => {
  const {
    organizationsExtended,
    isLoading,
    total,
    localization,
    activePage,
    setActivePage,
    perPage,
    onPerPageChange,
    columnOrderBy,
    onColumnOrderByChange,
    columnOrderDirection,
    onColumnOrderDirectionChange,
    selectedOrganizationsExtended,
    onRowSelectionChange,
    createColumns,
    options,
  } = props;

  const { t } = useTranslations();
  const navigate = useNavigate();

  const columns = useMemo(() => createColumns([
    {
      title: t('organizationsExtendedPage.organization'),
      field: 'name',
      render: (rowData) => (
        <TypographyWithTooltip variant="body2">
          {rowData.name}
        </TypographyWithTooltip>
      ),
      width: '17%',
    },
    {
      title: t('organizationsExtendedPage.region'),
      field: 'region',
      render: (rowData) => (
        <TypographyWithTooltip variant="body2">
          {rowData.region}
        </TypographyWithTooltip>
      ),
      width: '15%',
    },
    {
      title: t('organizationsExtendedPage.country'),
      field: 'country',
      render: (rowData) => (
        <TypographyWithTooltip variant="body2">
          {rowData.country}
        </TypographyWithTooltip>
      ),
      width: '15%',
    },
    {
      title: t('organizationsExtendedPage.vat'),
      field: 'vat',
      render: (rowData) => (
        <TypographyWithTooltip variant="body2">
          {rowData.vat}
        </TypographyWithTooltip>
      ),
      width: '12%',
    },
    {
      title: (
        <TypographyWithTooltip
          variant="subtitle1"
          tooltipProps={COLUMN_TOOLTIP_PROPS}
          lineClamp={5}
          title={t('organizationsExtendedPage.committedLicenses.columnTooltip')}
        >
          {t('organizationsExtendedPage.committedLicenses')}
        </TypographyWithTooltip>
      ),
      field: 'committedLicenseCount',
      render: (rowData) => (
        <TypographyWithTooltip variant="body2">
          {rowData.committedLicenseCount}
        </TypographyWithTooltip>
      ),
      width: '13%',
    },
    {
      title: (
        <TypographyWithTooltip
          variant="subtitle1"
          tooltipProps={COLUMN_TOOLTIP_PROPS}
          lineClamp={5}
          title={t('organizationsExtendedPage.licenses.columnTooltip')}
        >
          {t('organizationsExtendedPage.licenses')}
        </TypographyWithTooltip>
      ),
      field: 'licenseCount',
      render: (rowData) => (
        <TypographyWithTooltip variant="body2">
          {rowData.licenseCount}
        </TypographyWithTooltip>
      ),
      width: '13%',
    },
    {
      title: t('organizationsExtendedPage.partner'),
      field: 'partnerName',
      render: (rowData) => (
        <TypographyWithTooltip variant="body2">
          {rowData.partnerName}
        </TypographyWithTooltip>
      ),
      width: '15%',
      sorting: false,
    },
  ]), [createColumns, t]);

  const handleOrganizationRowClick = useCallback<
    NonNullable<IMaterialTableProps<IOrganizationsExtendedTableRow>['onRowClick']>
  >((event, organization) => {
    const { organizationId } = organization!;
    navigate(`${PageRouteEnum.Organizations}/${organizationId}`);
  }, [navigate]);

  return (
    <Table
      t={t}
      totalItems={total}
      columns={columns}
      data={organizationsExtended}
      isLoading={isLoading}
      options={options}
      activePage={activePage}
      onActivePageChange={setActivePage}
      perPage={perPage}
      onPerPageChange={onPerPageChange}
      columnOrderBy={columnOrderBy}
      onColumnOrderByChange={onColumnOrderByChange}
      columnOrderDirection={columnOrderDirection}
      onColumnOrderDirectionChange={onColumnOrderDirectionChange}
      selectedRows={selectedOrganizationsExtended}
      onSelectionChange={onRowSelectionChange}
      localization={localization}
      onRowClick={handleOrganizationRowClick}
      rowSelectByKey="organizationId"
      rowHeight={51}
    />
  );
};

export default memo(OrganizationsExtendedTable);
